import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import adminImg from "../../../assets/images/adminImg.png";
import { toast } from "react-hot-toast";
import { Country, State, City } from "country-state-city";
import { ICountry, IState, ICity } from "country-state-city";
function AddDogProfile() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const params = useParams();
  const [state, setState] = useState("");
  const [storeState, setStoreState] = useState("");
  const [stateValue, setStateValue] = useState("");

  const [data, setData] = useState([]);
  const getData = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL =
      SERVER_URL +
      `/api/admin/get-single-dog-profile/${id}/${params.unique_id}/${params.band_id}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    setData(result.data);
    setStoreState(result.data.state);
  };
  useEffect(() => {
    getData();
  }, []);
  const [dogInfo, setDogInfo] = useState({
    dog_name: "",
    dog_breed: "",
    dog_gender: "",
    dog_weight: "",
    dog_height: "",
    dog_age: "",
    neutered: "",
    // coat_type: "",
    // fur_length: "",
    activity_condition: "",
    bcs: "",
    state: "",
    city: "",
  });
  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setDogInfo({ ...dogInfo, [name]: value });
    console.log({ ...dogInfo, [name]: value });
  };

  const updateDogProfile = async (event) => {
    event.preventDefault();
    document.getElementById(
      "loginBtn"
    ).innerHTML = `<span class="load loading addMemberBtn"></span>`;

    const id = localStorage.getItem("adminId")?.replace(/"/g, "");

    var BASE_URL = SERVER_URL + `/api/admin/update-dog-profile/${id}`;
    const {
      dog_name,
      dog_breed,
      dog_gender,
      dog_weight,
      dog_height,
      dog_age,
      neutered,
      // coat_type,
      // fur_length,
      activity_condition,
      bcs,
      state,
      city,
    } = dogInfo;
    const formData = new FormData();
    formData.append("unique_id", params.unique_id);
    formData.append("band_id", params.band_id);
    formData.append("dog_name", dog_name);
    formData.append("dog_breed", dog_breed);
    formData.append("dog_gender", dog_gender);
    formData.append("dog_weight", dog_weight);
    formData.append("dog_height", dog_height);
    formData.append("dog_age", dog_age);
    formData.append("neutered", neutered);
    // formData.append("coat_type", coat_type);
    // formData.append("fur_length", fur_length);
    formData.append("activity_condition", activity_condition);
    formData.append("bcs", bcs);
    formData.append("state", state || stateValue.split("-")[1] || storeState);
    formData.append("city", city);
    // formData.append("image", image || ("image", adImage));
    const res = await fetch(BASE_URL, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
      },
      body: formData,
    });
    const data = await res.json();
    if (res.ok) {
      toast.success(data.message);
      setTimeout(() => {
        document.getElementById("loginBtn").innerHTML = `Update Profile`;
      }, 500);
      getData();
    } else {
      toast.error(data.message);

      setTimeout(() => {
        document.getElementById("loginBtn").innerHTML = `Update Profile`;
      }, 500);
      getData();
      // document.getElementById("reset").reset();
    }
  };

  // console.log(City.getCitiesOfCountry("IN"))
  // console.log(State.getStatesOfCountry("IN"));
  // console.log(City.getCitiesOfState("IN", "MP"));
  const stateData = State.getStatesOfCountry("IN").map((state) => ({
    value: state.name,
    displayValue: state.name,
    stateCode: state.isoCode,
  }));
  const stateDataValue = State.getStatesOfCountry("IN").map(
    (state) => state.name
  );
  const cityDataValue = City.getCitiesOfCountry("IN").map((city) => city.name);
  // console.log(stateDataValue);
  // console.log(cityDataValue);
  // console.log(stateValue.split("-")[1]);
  const cityData = City.getCitiesOfState(
    "IN",
    `${stateValue.split("-")[0]}`
  ).map((city) => ({
    value: city.name,
    displayValue: city.name,
  }));
  const [dogBreed, setDogBreed] = useState([]);

  const getBreedData = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/get-breed/${id}`;
    // // Check if cached data exists
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    setDogBreed(result.data);
  };
  useEffect(() => {
    getBreedData();
  }, []);

  return (
    <div className="content-wrapper">
      <div className="row  mt-3">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Update Dog Profile</h4>

              {/* <div>
                <button className="btn btn-dark" onClick={buttonClick}>
                  Click check
                </button>
              </div> */}

              <form
                className="forms-sample"
                autoComplete="off"
                onSubmit={updateDogProfile}
              >
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="dog_name">Name</label>
                      <input
                        type="text"
                        name="dog_name"
                        className="form-control"
                        id="dog_name"
                        onChange={handleFormData}
                        defaultValue={
                          dogInfo.dog_name || (dogInfo.dog_name = data.dog_name)
                        }
                        placeholder="Dog Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    {" "}
                    <div className="form-group">
                      <label htmlFor="dog_breed">Breed</label>
                      <select
                        className="form-control"
                        id="dog_breed"
                        name="dog_breed"
                        onChange={handleFormData}
                        value={
                          dogInfo.dog_breed ||
                          (dogInfo.dog_breed = data.dog_breed)
                        }
                      >
                        <option value="">Select Breed</option>
                        {dogBreed.map((option, index) => {
                          return (
                            <option key={index} value={option.breed}>
                              {option.breed}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {" "}
                    <div className="form-group">
                      <label htmlFor="dog_gender">Gender</label>
                      <select
                        className="form-control"
                        id="dog_gender"
                        name="dog_gender"
                        onChange={handleFormData}
                        value={
                          dogInfo.dog_gender ||
                          (dogInfo.dog_gender = data.dog_gender)
                        }
                      >
                        <option disabled>Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group passwordInputField">
                      <label htmlFor="dog_age">Age</label>
                      <input
                        type="text"
                        className="form-control"
                        id="dog_age"
                        placeholder="Age"
                        name="dog_age"
                        onChange={handleFormData}
                        defaultValue={
                          dogInfo.dog_age || (dogInfo.dog_age = data.dog_age)
                        }
                      />
                      <span>yr</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group passwordInputField">
                      <label htmlFor="dog_weight">Weight</label>
                      <input
                        type="text"
                        className="form-control"
                        id="dog_weight"
                        placeholder="Weight"
                        name="dog_weight"
                        onChange={handleFormData}
                        defaultValue={
                          dogInfo.dog_weight ||
                          (dogInfo.dog_weight = data.dog_weight)
                        }
                      />
                      <span>kg</span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group passwordInputField">
                      <label htmlFor="dog_height">Height</label>
                      <input
                        type="text"
                        className="form-control"
                        id="dog_height"
                        placeholder="Height"
                        name="dog_height"
                        onChange={handleFormData}
                        defaultValue={
                          dogInfo.dog_height ||
                          (dogInfo.dog_height = data.dog_height)
                        }
                      />
                      <span>cm</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="neutered">Neutered</label>
                      <select
                        className="form-control"
                        id="neutered"
                        name="neutered"
                        onChange={handleFormData}
                        value={
                          dogInfo.neutered || (dogInfo.neutered = data.neutered)
                        }
                      >
                        <option disabled>Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="coat_type">Coat Type</label>
                      <select
                        className="form-control"
                        id="coat_type"
                        name="coat_type"
                        onChange={handleFormData}
                        value={
                          dogInfo.coat_type ||
                          (dogInfo.coat_type = data.coat_type)
                        }
                      >
                        <option disabled>Select</option>
                        <option value="single">Single</option>
                        <option value="double">Double</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="fur_length">Fur Length</label>
                      <select
                        className="form-control"
                        id="fur_length"
                        name="fur_length"
                        onChange={handleFormData}
                        value={
                          dogInfo.fur_length ||
                          (dogInfo.fur_length = data.fur_length)
                        }
                      >
                        <option disabled>Select</option>
                        <option value="long">Long</option>
                        <option value="medium">Medium</option>
                        <option value="short">Short</option>
                      </select>
                    </div>
                  </div> */}
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="city">City</label>
                      <select className="form-control" id="city" name="city">
                        <option disabled>Select</option>
                        <option value="mumbai">mumbai</option>
                        <option value="indore">indore</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="state">State</label>
                      <select className="form-control" id="state" name="state">
                        <option disabled>Select</option>
                        <option value="maharashtra">Maharashtra</option>
                        <option value="madhya pradesh">Madhya Pradesh</option>
                      </select>
                    </div>
                  </div> */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="activity_condition">
                        Activity Condition
                      </label>
                      <select
                        className="form-control"
                        id="activity_condition"
                        name="activity_condition"
                        onChange={handleFormData}
                        value={
                          dogInfo.activity_condition ||
                          (dogInfo.activity_condition = data.activity_condition)
                        }
                      >
                        <option value="">Select Activity Condition</option>
                        <option value="Inactive_adult">Inactive adult</option>
                        <option value="Active_adult">Active adult</option>
                        <option value="Light_work">Light work</option>
                        <option value="Medium_work">Medium work</option>
                        <option value="High_work">High work</option>
                        <option value="gestation_week_1_4">
                          Gestation week 1-4
                        </option>
                        <option value="gestation_week_5">
                          Gestation week 5
                        </option>
                        <option value="gestation_week_6_8">
                          Gestation week 6-8
                        </option>
                        <option value="gestation_week_9">
                          Gestation week 9
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group passwordInputField">
                      <label htmlFor="bcs">BCS (Body Condition Score)</label>
                      <input
                        type="number"
                        className="form-control"
                        id="BCS"
                        placeholder="Body Condition Score"
                        name="bcs"
                        onChange={handleFormData}
                        value={dogInfo.bcs || (dogInfo.bcs = data.bcs)}
                        min="1"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="state">State</label>
                      <select
                        className="form-control"
                        id="state"
                        name="state"
                        onChange={(e) => setStateValue(e.target.value)}
                      >
                        <option value={data.state}>{data.state}</option>
                        {stateData.map((option, index) => {
                          return (
                            <option
                              key={index}
                              value={
                                option.stateCode + "-" + option.displayValue
                              }
                            >
                              {option.displayValue}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="city">City</label>
                      <select
                        className="form-control"
                        id="city"
                        name="city"
                        onChange={handleFormData}
                        value={dogInfo.city || (dogInfo.city = data.city)}
                      >
                        {/* <option value="">Select</option> */}
                        <option disabled>{data.city}</option>

                        {cityData.map((option, index) => {
                          return (
                            <option key={index} value={option.value}>
                              {option.displayValue}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary mr-2 px-5 py-3"
                    id="loginBtn"
                  >
                    Update Profile
                  </button>{" "}
                  <button
                    type="submit"
                    className="btn btn-dark mr-2 px-5 py-3"
                    onClick={() => {
                      navigate("/dog-profile");
                    }}
                  >
                    Go back
                  </button>
                </div>

                {/* <div className="form-group">
                    <button
                      id="loginBtn"
                      type="submit"
                      className="form-control btn btn-primary rounded signInBtn px-3"
                    >
                      Sign In
                    </button>
                  </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDogProfile;
