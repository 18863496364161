import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { useNavigate } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import toast from "react-hot-toast";
const DogProfile = () => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  // const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [pageSize, setPageSize] = useState(10); // Set rowData to Array of Objects, one Object per Row
  const navigate = useNavigate();
  // ========================================[For Api and Data set Start]===================================================
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [uniqueId, setUniqueId] = useState("");
  const [bandId, setBandId] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math?.min(startItem + data?.length - 1, totalRecords);
  const totalPages = Math.ceil(totalRecords / pageSize);
  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const getData = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL =
      SERVER_URL +
      `/api/admin/get-dog-profiles-data/${id}?page=${currentPage}&pageSize=${pageSize}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    if (res.ok) {
      // const updatedData = result.data?.map((row, index) => {
      //   return {
      //     ...row,
      //     index: (currentPage - 1) * Object.keys(result).length + index + 1,
      //   };
      // });
      setData(result.data);
      setTotalRecords(result.totalCount);
      // setData(result.data);
    } else {
      toast(result.message);
    }
  };
  // const getData = async () => {
  //   const id = localStorage.getItem("adminId")?.replace(/"/g, "");
  //   let BASE_URL = SERVER_URL + `/api/admin/get-dog-profiles-data/${id}`;
  //   const res = await fetch(BASE_URL, {
  //     method: "GET",
  //     headers: {
  //       authorization: `Bearer ${JSON.parse(
  //         localStorage.getItem("logged_res")
  //       )}`,
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   const result = await res.json();
  //   const updatedData = result.data?.map((row, index) => {
  //     return {
  //       ...row,
  //       index: (currentPage - 1) * Object.keys(result).length + index + 1,
  //     };
  //   });
  //   setData(updatedData);
  //   // setData(result.data);
  // };

  const deleteSelected = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/delete-selected-dog-profiles/${id}`;
    const res = await fetch(BASE_URL, {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dogId: storedSelected,
      }),
    });
    const result = await res.json();
    if (res.ok) {
      toast.success(result.message);
      getData();
    } else {
      toast.error(result.message);
      getData();
    }
  };

  function handleDeletion() {
    if (storedSelected.length > 0) {
      deleteSelected();
    } else if (storedSelected.length === 0) {
      toast.error("Select atleast one row to perform deletion");
    }
  }
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getData(newPage);
  };
  const onPageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value));
    gridRef.current.api.showLoadingOverlay();
    setCurrentPage(1);
  };
  useEffect(() => {
    getData();
  }, [currentPage, pageSize]);

  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);

  // =========================================[For Diamond trn_log Data]============================================
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "S.No",
      field: "index",
      valueGetter: (params) => {
        const currentPageContext = params.context.currentPage;
        const pageSizeContext = params.context.pageSize;
        return (
          (currentPageContext - 1) * pageSizeContext + params.node.rowIndex + 1
        );
      },
      width: 150,
      // For selection
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    { headerName: "Dog Id", field: "id", width: 150 },
    { headerName: "User/Guardian Name", field: "username", minWidth: 205 },
    { headerName: "Unique Id (User's)", field: "unique_id", minWidth: 300 },
    { headerName: "Band Id/Serial Number", field: "band_id", minWidth: 250 },
    { headerName: "Dog Name", field: "dog_name", minWidth: 205 },
    { headerName: "Breed", field: "dog_breed" },
    {
      headerName: "Age",
      field: "dog_age",
      width: 150,
      cellRenderer: (params) =>
        params.value !== "" ? params.value + " yr" : "-",
    },
    {
      headerName: "Gender",
      field: "dog_gender",
      width: 150,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Weight",
      field: "dog_weight",
      width: 150,
      cellRenderer: (params) =>
        params.value !== "" ? params.value + " kg" : "-",
    },
    {
      headerName: "Height",
      field: "dog_height",
      width: 150,
      cellRenderer: (params) =>
        params.value !== "" ? params.value + " cm" : "-",
    },
    {
      headerName: "Neutered",
      field: "neutered",
      width: 150,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Fur Length",
      field: "fur_length",
      width: 150,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Coat type",
      field: "coat_type",
      width: 150,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Activity Condition",
      field: "activity_condition",
      minWidth: 205,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "BCS",
      field: "bcs",
      width: 150,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "State",
      field: "state",
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "City",
      field: "city",
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Created At",
      field: "created_at",
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
  ]);

  // Col Defs For Sample Pending Data End===============================================
  // ==============================================[For selected Data]=========================================
  const [selectedData, setSelectedData] = useState([]);
  const [rmvBtn, setRmvBtn] = useState(false);
  //   console.log(selectedData);
  const storedSelected = selectedData.map((sel) => {
    return sel.data.id;
  });
  // console.log(storedSelected);

  const storedUniqueId = selectedData.map((sel) => {
    return sel.data.unique_id;
  });

  const storedBandId = selectedData.map((sel) => {
    return sel.data.band_id;
  });

  function handleUpdate() {
    if (storedSelected.length === 1) {
      navigate(`/update-dog-profile/${storedUniqueId[0]}/${storedBandId[0]}`);
    } else if (storedSelected.length === 0) {
      toast.error("Select atleast one row to update");
    } else {
      toast.error("Select only one row to update");
    }
  }

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 1) {
      setRmvBtn(true);
    }
    if (rowCount < 2) {
      setRmvBtn(false);
    }
    // console.log(event.api.getSelectedNodes());
    setSelectedData(event.api.getSelectedNodes());
    // window.alert("selection changed, " + rowCount + " rows selected");
  }, []);
  // ==============================================[For selected data End]=========================================

  // ============================================[For Remove selected data Start]====================================
  const removeSelected = useCallback(() => {
    const selectedRowNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedRowNodes.map(function (rowNode) {
      return rowNode.data.id;
    });
    const confirmation = window.confirm(
      "Are you sure? You want to delete multiple data!"
    );
    if (confirmation) {
      const filteredData = data.filter(function (dataItem) {
        return selectedIds.indexOf(dataItem.id) < 0;
      });
      setData(filteredData);
      setRmvBtn(false);
    }
  }, [data]);
  // ============================================[For Remove selected data End]====================================

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    // make every column sortable
    sortable: true,
    // make every column filter
    filter: true,
    filterParams: {
      buttons: ["reset", "apply"],
      // debounceMs: 100,
    },
    // enable floating filters by default
    floatingFilter: true,
    // make columns resizable
    resizable: true,
  }));

  // Example load data from sever
  // useEffect(() => {
  //   fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
  //     .then((result) => result.json())

  //     .then((rowData) => setRowData(rowData));
  // }, []);

  function setPagination(event) {
    event.preventDefault();
    setPageSize(event.target.value);
  }

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleButtonClick = () => {
    getData();
  };
  return (
    <div className="content-wrapper">
      <div className="row mt-3">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex manageLinkTable justify-content-between align-items-center mb-3">
                <h4 className="table_title card-title mb-1">
                  Dog Profile{" "}
                  <span>
                    <button
                      className="btn btn-sm bg-white btn-icon-text border"
                      onClick={handleButtonClick}
                    >
                      <i
                        className="typcn typcn-refresh"
                        style={{ height: "4px", width: "4px" }}
                      ></i>{" "}
                    </button>
                  </span>
                </h4>
                <div className="manageTablesOptions">
                  {/* <div>
                    {rmvBtn && (
                      <button
                        onClick={removeSelected}
                        className="btn removeAllBtn btn-sm btn-outline-danger btn-icon-text"
                      >
                        Remove Selected
                        <i
                          className="fa fa-trash btn-icon-append"
                          aria-hidden="true"
                        ></i>
                      </button>
                    )}
                  </div> */}
                  <div style={{ margin: "auto 8px" }}>
                    <button
                      type="button"
                      className="btn btn-sm bg-white border"
                      onClick={handleUpdate}
                    >
                      <i
                        className="fa fa-pen"
                        style={{
                          height: "8px",
                          width: "8px",
                          paddingRight: "16px",
                        }}
                      ></i>
                    </button>
                  </div>
                  <div style={{ margin: "auto 8px" }}>
                    <button
                      type="button"
                      className="btn btn-sm bg-white border"
                      onClick={handleDeletion}
                    >
                      <i
                        className="fa fa-trash"
                        style={{
                          height: "8px",
                          width: "8px",
                          paddingRight: "16px",
                        }}
                      ></i>
                    </button>
                  </div>
                  <div style={{ margin: "auto 8px" }}>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary border"
                      style={{ height: "36px" }}
                      onClick={() => {
                        navigate("/create-dog-profile");
                      }}
                    >
                      Create Profile
                    </button>
                  </div>
                  <div>
                    <select
                      className="dropdown setpageSelection show form-control"
                      name="pagination"
                      style={{
                        padding: ".5rem .75rem",
                        borderRadius: "4px",
                      }}
                      onChange={onPageSizeChange}
                      // onChange={(event) => setPagination(event)}
                    >
                      <option value={10}>show 10 rows</option>
                      <option value={20}>show 20 rows</option>
                      <option value={25}>show 25 rows</option>
                      <option value={50}>show 50 rows</option>
                    </select>
                  </div>

                  {/* <div>
                    <button
                      type="button"
                      className="btn excelBtn btn-sm btn-info btn-icon-text"
                      onClick={onBtnExport}
                    >
                      Excel
                      <i className="typcn typcn-download btn-icon-append"></i>
                    </button>
                  </div> */}
                </div>
              </div>

              {/* On div wrapping Grid a) specify theme CSS className className and b) sets Grid size */}
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: 620 }}
              >
                <AgGridReact
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={data} // Row Data for Rows
                  context={{ currentPage, pageSize }}
                  columnDefs={columnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  // paginationPageSize={paginationRow}
                  suppressExcelExport={true}
                  sortingOrder={sortingOrder}
                  enableCellTextSelection={true} // For cell data selection
                  rowSelection={"multiple"} // For row selection
                  suppressRowClickSelection={true} // For row selection
                  onSelectionChanged={onSelectionChanged} // For Getting number of rows selected
                  rowHeight={50}
                  // pagination={true}
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                />
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <div
                      id="order-listing_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="order-listing_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing {startItem} to {endItem} of {totalRecords}{" "}
                            entries{" "}
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="order-listing_paginate"
                          >
                            <ul className="pagination">
                              <li
                                className={`paginate_button page-item ${
                                  currentPage === 1 ? "disabled" : ""
                                }`}
                                id="order-listing_previous"
                                onClick={() => {
                                  if (currentPage !== 1) {
                                    handlePageChange(1);
                                  }
                                }}
                                disabled={currentPage === 1}
                              >
                                <button
                                  aria-controls="order-listing"
                                  data-dt-idx="0"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  <i
                                    className="fa fa-angle-double-left"
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                    }}
                                  ></i>
                                </button>
                              </li>
                              <li
                                className={`paginate_button page-item ${
                                  currentPage === 1 ? "disabled" : ""
                                }`}
                                id="order-listing_previous"
                                onClick={() => {
                                  if (currentPage !== 1) {
                                    handlePageChange(currentPage - 1);
                                  }
                                }}
                                disabled={currentPage === 1}
                              >
                                <button
                                  aria-controls="order-listing"
                                  data-dt-idx="0"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  <i
                                    className="fa fa-chevron-left"
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                    }}
                                  ></i>
                                </button>
                              </li>

                              <li className="paginate_button page-item active">
                                <p
                                  aria-controls="order-listing"
                                  className="page-link"
                                >
                                  Page {currentPage} of {totalPages}
                                </p>
                              </li>

                              <li
                                className={`paginate_button page-item ${
                                  currentPage * pageSize >= totalRecords
                                    ? "disabled"
                                    : ""
                                }`}
                                id="order-listing_next"
                                onClick={() => {
                                  if (currentPage * pageSize < totalRecords) {
                                    handlePageChange(currentPage + 1);
                                  }
                                }}
                              >
                                <button
                                  aria-controls="order-listing"
                                  data-dt-idx="2"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  <i
                                    className="fa fa-chevron-right"
                                    style={{ height: "4px", width: "4px" }}
                                  ></i>
                                </button>
                              </li>
                              <li
                                className={`paginate_button page-item ${
                                  currentPage * pageSize >= totalRecords
                                    ? "disabled"
                                    : ""
                                }`}
                                id="order-listing_next"
                                onClick={() => {
                                  if (currentPage !== totalPages) {
                                    handlePageChange(totalPages);
                                  }
                                }}
                              >
                                <button
                                  aria-controls="order-listing"
                                  data-dt-idx="2"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  <i
                                    className="fa fa-angle-double-right"
                                    style={{ height: "4px", width: "4px" }}
                                  ></i>
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DogProfile;
